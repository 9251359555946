// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBB5sYiqlpV-7NBuc7vRongVGVWKjI3o_w",
  authDomain: "nkil-85977.firebaseapp.com",
  databaseURL: "https://nkil-85977-default-rtdb.firebaseio.com",
  projectId: "nkil-85977",
  storageBucket: "nkil-85977.appspot.com",
  messagingSenderId: "410662533890",
  appId: "1:410662533890:web:3b4ea4903cb03786c0201b",
  measurementId: "G-G5EM9K4ME8"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };